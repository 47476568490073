import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import curtAndFamily from "../../images/curt-and-family.jpeg";
import bill from "../../images/bill.jpeg";

const OurLeadersPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full p-16 bg-cyan-900">
          <h1 className="text-white text-5xl font-serif text-center md:text-left">
            Our Leaders
          </h1>
        </div>

        <div className="w-full pl-8 md:pl-16 pt-8">
          <h2 className="text-xl font-bold text-cyan-700">The Elders</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row w-full py-8 px-8 md:px-16 gap-6 md:gap-16">
          <LeaderRow
            img={curtAndFamily}
            name="Curt Chunat"
            title="Lead Elder"
            description={
              <>
                Curt and his wife Carrie, have attended Wellspring Bible Church
                for the past 10 years. They have five children, Sidney, Mallory,
                Eli, Andy and Johnny. They have a passion for service and
                hospitality.
              </>
            }
          />
        </div>

        <div className="w-full pl-8 md:pl-16 md:pt-16">
          <h2 className="text-xl font-bold text-cyan-700">The Deacons</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row w-full py-8 px-8 md:px-16 gap-6 md:gap-16">
          <LeaderRow
            img={bill}
            name="Bill Saeger"
            title="Deacon"
            description={
              <>
                Bill is a father to one daughter, Brea, who lives in West
                Virginia with her son, Conner. Bill has attended Wellspring
                since becoming a Christian. At that time he was baptized and
                became a member. He has since become a deacon of Wellspring. He
                retired several years ago, but that only lasted 2 months. He
                then went to work part time for another church, where his eyes
                were really opened to what it takes to run a large church. On
                the weekends, he does flea markets. He enjoys being outside and
                selling things one on one. This has led Bill to sharing his
                faith with people. He also enjoys repurposing things. He's
                blessed to have a family and a church family. Both of whom have
                helped him in more ways than they know.
              </>
            }
          />
          <LeaderRow
            name="Dave Troyer"
            title="Deacon"
            description={
              <>
                Dave Troyer is married to Betti Troyer, and they have 2 adult children.
              </>
            }
          />
        </div>
        <div className="w-full bg-sky-800 p-8 md:p-16 md:mt-8">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 justify-between">
            <div className=" text-white text-3xl font-light">
              Plan a visit with us!
            </div>
            <a
              href="/about/what-to-expect"
              className="rounded-full  bg-white hover:bg-gray-200 text-cyan-700 text-sm sm:text-lg px-8 py-3"
            >
              What to expect
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

function LeaderRow({ img, name, title, description }) {
  return (
    <>
      <div>
        {img && <img src={img} className="w-full object-cover shadow-xl rounded-3xl" />}
      </div>
      <div className="mb-8 md:mb-0">
        <div className="text-4xl text-cyan-900 font-serif">{name}</div>
        <div className="text-lg text-gray-500 font-light">{title}</div>
        <div className="text-md text-cyan-900 mt-2">{description}</div>
      </div>
    </>
  );
}

export default OurLeadersPage;
